$n-dim: 4;
// $n: pow($n-dim, 2); //Orig var.
$n: 16; //FIX: Stupid reason not accepting a 'pow()' for "$i from to __"
$n-faces: 3;
$c: #fff #39b791 #39b791;
$cL: #39b791;
$BG: rgb(14, 12, 16);

$rot: rotateX(90deg) rotateY(90deg) ();
$edge: 2.75em;
$dist: $edge + .75em;
$t: 3.15s;

// .loadings {
//   font-size: 12px;
//   overflow: hidden;
// }




#colorWrapper {

  width: 100vw;
  height: 100vh;
  background-color: $BG ;
  transition: background-color 0.35s ease-out;

  .grid {
    width: (($edge * 4) + (0.5em * 3)) * 0.7 + 0em;
    height: 6.25em;

    //  background-color: white;
    &,
    * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform-style: preserve-3d;
    }

    transform: rotateX(-35deg) rotateY(-45deg);
    /* animation: revolveGrid 6s ease infinite alternate;
    @keyframes revolveGrid {
      from {transform: rotateX(-65deg) rotateY(-45deg);}
      to {transform: rotateX(-15deg) rotateY(-45deg);}
    } */
  }

  .cube {
    @for $i from 0 to $n {
      $j: $i%$n-dim;
      $k: floor($i/$n-dim);
      $tx: (
        $j - .5*($n-dim - 1))*$dist ;
      $tz: (
        $k - .5*($n-dim - 1))*$dist ;

      &:nth-child(#{$i + 1}) {
        transform: translate3d($tx , 0, $tz
        );

      .lifter,
      .cube__face:nth-child(1) {
        animation-delay:
          ($j + ($n-dim - 1 - $k))*0.0466*$t;
      }
    }
  }

  &__face {
    margin: (-10 * $edge) + 20;
    width: $edge;
    height: $edge;
    transition: background 0.35s ease-out;

    @for $i from 0 to $n-faces {
      &:nth-child(#{$i + 1}) {
        transform: nth($rot, $i + 1) translateZ(.5*$edge);
        background: nth($c, $i + 1);
      }

      &:nth-child(1) {
        //  box-shadow: 350px 220px 90px rgba(0,0,0,0.1), 320px 250px 50px rgba(0,0,0,0.03);
        background: nth($c, 1);
        animation: aniCL ($t / 4) cubic-bezier(.4, 0.2, .4, 0.6) alternate infinite;
      }
    }
  }
}

.lifter {
  transform-origin: 0 .5 * $edge;
  transform: scaleY(0);
  animation: ani ($t / 4) cubic-bezier(.5, .1, .5, .9) alternate infinite; // cubic-bezier(.625,.075,.4,.925)
}

@keyframes ani {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(0.533);
  }
}

//  @keyframes aniCL {
//    0% { background: nth($cL, 1); }
//    100% { background: nth($c, 1); }
//  }

/*@keyframes aniCL {
    0%, 25%, 50%, 75%, 100% { background: nth($cL, 1); }
    12%, 36%, 64%, 89%,  { background: nth($c, 1); }
  }*/
}

/*
.shadow {
  position: relative;
  top: 50vh;
  margin: 0 auto; margin-top: -200px;
  width: 200px; height: 200px;
  background-color: transparent;
  transform: rotateX(60deg) rotate(45deg);
  z-index: -1;
}
.flatShadow {
  margin-top: 20px;
  background-color: rgba(60,0,0,0.008);
}
// .dropShadow { box-shadow: 420px 400px 160px rgba(90,0,0,0.1);}  
*/



#colorWrapper.honeyComb {
  $c: #FFF2D0 #FFD792 #FCB367;
  $cL: #FFF8E4;
  $BG: rgb(250, 252, 251);

  background-color: $BG;

  .cube {
    &__face {
      @for $i from 0 to $n-faces {
        &:nth-child(#{$i + 1}) {
          background: nth($c, $i + 1);
        }

        &:nth-child(1) {
          background: nth($c, 1);
          animation: aniCL-honeyComb ($t / 4) cubic-bezier(.4, 0.2, .4, 0.6) alternate infinite;
        }
      }
    }
  }

  @keyframes aniCL-honeyComb {
    0% {
      background: nth($cL, 1);
    }

    100% {
      background: nth($c, 1);
    }
  }
}

#colorWrapper.whtSlate {
  $c: #D0DAD9 #B9C6C7 #E4EDEC;
  $cL: #D0DAD9;
  $BG: rgb(250, 252, 251);

  background-color: $BG;

  .cube {
    &__face {
      @for $i from 0 to $n-faces {
        &:nth-child(#{$i + 1}) {
          background: nth($c, $i + 1);
        }

        &:nth-child(1) {
          background: nth($c, 1);
          animation: aniCL-whtSlate ($t / 4) cubic-bezier(.4, 0.2, .4, 0.6) alternate infinite;
        }
      }
    }
  }

  @keyframes aniCLwht-Slate {
    0% {
      background: nth($cL, 1);
    }

    100% {
      background: nth($c, 1);
    }
  }
}

#colorWrapper.phosGreen-II {
  $c: #0A1819 #102928 #15f4c2;
  $cL: #0d2c2f;
  $BG: rgb(16, 18, 22);

  background-color: $BG;

  .cube {
    &__face {
      @for $i from 0 to $n-faces {
        &:nth-child(#{$i + 1}) {
          background: nth($c, $i + 1);
        }

        &:nth-child(1) {
          background: nth($c, 1);
          animation: aniCL-phosGreen-II ($t / 4) cubic-bezier(.4, 0.2, .4, 0.6) alternate infinite;
        }
      }
    }
  }

  @keyframes aniCL-phosGreen-II {
    0% {
      background: nth($cL, 1);
    }

    100% {
      background: nth($c, 1);
    }
  }
}

#colorWrapper.gemBlue {
  $c: #39b791 #d3d3d3 #d7d7d7;
  $cL: #39b740;
  $BG: rgb(0,0,0,0.6); //orig rgb(12, 12, 20); //LightBG rgb(250, 251, 252

  background-color: $BG;

  .cube {
    &__face {
      @for $i from 0 to $n-faces {
        &:nth-child(#{$i + 1}) {
          background: nth($c, $i + 1);
        }

        &:nth-child(1) {
          background: nth($c, 1);
          animation: aniCL-gemBlue ($t / 4) cubic-bezier(.4, 0.2, .4, 0.6) alternate infinite;
        }
      }
    }
  }

  @keyframes aniCL-gemBlue {
    0% {
      background: nth($cL, 1);
    }

    100% {
      background: nth($c, 1);
    }
  }
}

/*/\\/   RIPPLE CLICK FX ---Start----   /\/\*/
.inkAnchor {
  // display: inline-block !important; 
  cursor: pointer;
  /*since the links are dummy without href values*/
  /*prevent text selection*/
  user-select: none;
  /*static positioned elements appear behind absolutely positioned siblings(.ink in this case) hence we will make the links relatively positioned to bring them above .ink*/
  position: relative;
}

/*.ink styles - the elements which will create the ripple effect. The size and position of these elements will be set by the JS code. Initially these elements will be scaled down to 0% and later animated to large fading circles on user click.*/
.button:active {
  transition: box-shadow 0.15s ease-out;
  box-shadow: 0 0.5em 0.33em -0.33em rgba(0, 0, 0, 0.12), 0 0.33em 1em -0.25em rgba(0, 0, 0, 0.2);
}

.whtSlate_B .ink {
  background-color: #B9C6C7;
}

.honeyComb_B .ink {
  background-color: #FFD792;
}

.phosGreen-II_B .ink {
  background-color: #15f4c2;
}

.gemBlue_B .ink {
  background-color: #39b791;
}

.ink {
  // font-size: 100%; //defines Em-size
  display: block;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  pointer-events: none !important;
  //  mix-blend-mode: color;
  //  color: black;
  // box-shadow: inset 0 0 0 0em;
  // 	background-color: black;
  //  mix-blend-mode: difference;
  //  z-index: -2;
}

/*animation effect*/
.ink.animateInk {
  animation: ripple 0.9s cubic-bezier(0.2, 0.2, 0, 1) forwards 1;
}

@keyframes ripple {
  0% {
    opacity: 0.6;
    transform: scale(0);
    /*box-shadow: inset 0 0 0 12em ;*/
  }

  100% {
    opacity: 0.0;
    transform: scale(1);
    /* box-shadow: inset 0 0 0 0em ;*/
  }
}

#loadingText {
  font-size: 15px;
  color: #39b791;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}